// components/AllowChangePlan.js
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  Button,
  Select,
  FormLabel,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react';

import BackButton from './BackButton';
import LoadingModal from './LoadingModal';
import {
  firestore,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from '../firebaseConfig';

/**
 * プラン変更画面
 * User.js から user オブジェクトを route state 経由で受け取って使う。
 */
const AllowChangePlan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { studioId, userId } = useParams();

  // User.js から受け取ったユーザー情報
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState(location.state?.user || null);

  // ローディング関連
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  // 選択プラン
  const [selectedPlan, setSelectedPlan] = useState('');
  const [planNumber, setPlanNumber] = useState(null);

  // 取得したプラン候補リスト
  const [plansOptions, setPlansOptions] = useState([]);

  // プラン変更期限チェック（例：毎月20日23:59）
  const [isDeadlinePassed, setIsDeadlinePassed] = useState(false);

  // プラン番号→名称
  const plans = {
    0: '回数券ご利用中',
    2: '月２回プラン',
    4: '月４回プラン',
    6: '月６回プラン',
    8: '月８回プラン',
  };

  // ─────────────────────────────────────
  // 1. user が存在しない場合のハンドリング
  // （ページ直アクセスなどで state が無い場合）
  // ─────────────────────────────────────
  useEffect(() => {
    if (!user) {
      // Firestoreから再取得してもいいし、無い場合はエラー表示だけでもOK
      console.log('User information not found in location.state. Redirect...');
      // 例: 一旦User.jsへ戻す:
      navigate(`/studios/${studioId}/users/${userId}`);
    }
  }, [user, navigate, studioId, userId]);

  // ─────────────────────────────────────
  // 2. プラン変更期限（毎月20日23:59）チェック
  // ─────────────────────────────────────
  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth(); // 0-11

    const deadline = new Date(year, month, 20, 23, 59, 59);
    if (now > deadline) {
      setIsDeadlinePassed(true);
    }
  }, []);

  // ─────────────────────────────────────
  // 3. Firestore からプラン一覧を取得
  // ─────────────────────────────────────
  useEffect(() => {
    const fetchPlanProducts = async () => {
      setLoading(true);
      try {
        const productsCollectionRef = collection(firestore, 'products');
        const qy = query(
          productsCollectionRef,
          where('active', '==', true),
          where('stripe_metadata_type', '==', 'live'),
          where('stripe_metadata_category', '==', 'plan')
        );

        const querySnapshot = await getDocs(qy);
        const productsData = await Promise.all(
          querySnapshot.docs.map(async doc => {
            const productData = { id: doc.id, ...doc.data() };

            // そのProductの下のpricesコレクション
            const pricesCollectionRef = collection(
              firestore,
              `products/${doc.id}/prices`
            );
            const priceSnapshot = await getDocs(pricesCollectionRef);

            productData.prices = priceSnapshot.docs.map(priceDoc => ({
              id: priceDoc.id,
              ...priceDoc.data(),
            }));

            return productData;
          })
        );

        // user.plan と異なるプランだけに絞り込む例
        const unselectProductData = productsData.filter(
          product => Number(product.metadata.plan) !== user?.plan
        );

        // ユーザーの性別に合った price を絞り込み
        const planOptions = unselectProductData.map(product => {
          const matchedPrice = product.prices.find(
            p => p.metadata?.sex === user?.sex
          );
          return {
            value: matchedPrice?.id, // Stripe Price ID
            plan: product.metadata.plan, // 2,4,6,8 など
            label: product.name, // UI表示用
          };
        });

        setPlansOptions(planOptions);
      } catch (error) {
        console.error('プラン一覧の取得に失敗:', error);
      }
      setLoading(false);
    };

    if (user) {
      fetchPlanProducts();
    }
  }, [user]);

  // ─────────────────────────────────────
  // 4. Select変更時
  // ─────────────────────────────────────
  const handlePlanChange = e => {
    const priceId = e.target.value;
    setSelectedPlan(priceId);

    const found = plansOptions.find(opt => opt.value === priceId);
    if (found) {
      setPlanNumber(found.plan);
    } else {
      setPlanNumber(null);
    }
  };

  // ─────────────────────────────────────
  // 5. 「変更を確定」ボタン押下で Cloud Functions 呼び出し
  // ─────────────────────────────────────
  const handleSubmit = useCallback(async () => {
    if (!selectedPlan || !user?.subscriptionId) {
      console.log('必要な情報が足りません');
      return;
    }
    setUpdating(true);

    const functionUrl =
      'https://asia-northeast1-medy-system.cloudfunctions.net/update_subscription';

    const requestBody = {
      subscriptionId: user.subscriptionId,
      newPriceId: selectedPlan,
      // もしbilling_cycle_anchor等を指定したいなら追加
      // e.g. billingCycleAnchor: ・・・,
    };

    try {
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });
      const responseData = await response.json();
      console.log('サブスクリプション更新レスポンス:', responseData);

      // 成功したかどうかをチェック
      if (response.ok) {
        // ★ サブスク更新が成功したので、ユーザードキュメントを更新
        // planNumberは <月2回=2, 月4回=4, など> の数値を想定
        await updateDoc(doc(firestore, 'users', user.id), {
          plan: planNumber,
        });
        console.log('ユーザードキュメントのplanフィールドを更新:', planNumber);
      } else {
        console.error('サブスクリプション更新APIでエラー:', responseData.error);
      }

      setUpdating(false);

      // 成功後にユーザーの管理ページへ戻る or 成功画面へ飛ぶなど
      // ここでは一例として再度ユーザー詳細へ戻る
      navigate(`/studios/${studioId}/users/${userId}`);
    } catch (error) {
      console.error('サブスクリプションの更新に失敗:', error);
      setUpdating(false);
    }
  }, [
    selectedPlan,
    user.subscriptionId,
    user.id,
    navigate,
    studioId,
    userId,
    planNumber,
  ]);

  // ローディング中画面
  if (loading) {
    return <LoadingModal text="プラン情報を取得中..." />;
  }
  // プラン変更API呼び出し中画面
  if (updating) {
    return <LoadingModal text="プランを変更中..." />;
  }

  if (!user) {
    // user 未取得の場合
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner mr={3} />
        <Text fontSize="xl">ユーザー情報がありません</Text>
      </Flex>
    );
  }

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Heading as="h1" size="sm" textAlign="center" mt="0px" mb="20px">
        medy定期プランの変更（{user.fullName}さま）
      </Heading>

      <Text fontSize="sm" color="gray.600" textAlign="center">
        次回決済：{user.nextSubscriptionDate || 'なし'}
      </Text>

      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        {/* 現在のプラン */}
        <Heading as="h2" size="sm" textAlign="center" mt="20px" mb="20px">
          現在：『{plans[user?.plan] || '回数券ご利用中'}』
        </Heading>

        {/* 次のプラン選択 */}
        <FormLabel fontSize="sm" color="gray.600">
          切り替えるプランを選んで下さい
        </FormLabel>
        <Select
          placeholder="プランを選択"
          value={selectedPlan}
          onChange={handlePlanChange}
        >
          {plansOptions.map(plan => (
            <option key={plan.value} value={plan.value}>
              {plan.label} （月{plan.plan}回）
            </option>
          ))}
        </Select>

        <Flex flexDirection="column" alignItems="center" width="full">
          {isDeadlinePassed ? (
            <Button mt={4} colorScheme="red" isDisabled>
              プラン変更期限が切れました
            </Button>
          ) : (
            <Button
              mt={4}
              colorScheme="teal"
              onClick={handleSubmit}
              isDisabled={!selectedPlan}
            >
              変更を確定
            </Button>
          )}
        </Flex>

        <Box overflow="hidden" px={2} mt={4}>
          <Text fontSize="sm" color="gray.600">
            {isDeadlinePassed
              ? 'プランを変更する場合は、切り替えたい月の前月１日〜２０日の間にご申請ください。'
              : '回数の少ないプランに切り替えると専用枠はリセットされる場合があります。２０日を過ぎると翌月分変更ができなくなるのでご注意ください。'}
          </Text>
        </Box>
      </Box>

      <BackButton />

      <Box overflow="hidden" px={6} mb={4}>
        <Text fontSize="xs" color="gray.600" mt={4}>
          利用規約にもあります通り、決済・会計処理の都合上、期限を過ぎますと切替や返金などの対応ができません。
          <br />
          プラン登録がない（回数券のみ）の状態になると専用枠が外れる場合があります。
          <br />
          あらかじめご理解くださいませ。
        </Text>
        <br />
        <Text fontSize="sm" color="teal.700" textAlign="right">
          STUDIO medy
        </Text>
      </Box>
    </Container>
  );
};

export default AllowChangePlan;
