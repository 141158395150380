// components/User.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Text,
  Container,
  Heading,
  Flex,
  Spinner,
  useToast,
} from '@chakra-ui/react';

import FormLink from './FormLink';
import BackButton from './BackButton';
import {
  firestore,
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
} from '../firebaseConfig';

const User = () => {
  const { studioId, userId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [user, setUser] = useState(null);

  // 継続期間を計算する関数
  function calculateContinuationPeriod(createdAt) {
    const currentDate = new Date(); // 現在
    const createdDate = createdAt.toDate(); // Timestamp -> Date

    let yearDiff = currentDate.getFullYear() - createdDate.getFullYear();
    let monthDiff = currentDate.getMonth() - createdDate.getMonth();
    let dayDiff = currentDate.getDate() - createdDate.getDate();

    if (dayDiff < 0) {
      monthDiff--;
      const daysInPreviousMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      ).getDate();
      dayDiff += daysInPreviousMonth;
    }
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }

    return yearDiff > 0
      ? `${yearDiff}年${monthDiff}ヶ月${dayDiff}日`
      : `${monthDiff}ヶ月と${dayDiff}日`;
  }

  // ─────────────────────────────────────────────────────────
  // 1. ユーザー情報 + チケット + 予約情報の取得
  // ─────────────────────────────────────────────────────────
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userDocRef = doc(firestore, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);

        const studioDocRef = doc(firestore, 'studios', studioId);
        const studioDocSnap = await getDoc(studioDocRef);

        if (userDocSnap.exists() && studioDocSnap.exists()) {
          const userData = { id: userDocSnap.id, ...userDocSnap.data() };
          const studioData = {
            id: studioDocSnap.id,
            slug: studioDocSnap.data().slug,
          };

          // unused tickets
          const ticketsColRef = collection(userDocRef, 'tickets');
          const unusedTicketsQuery = query(
            ticketsColRef,
            where('used', '==', false)
          );
          const ticketsSnapshot = await getDocs(unusedTicketsQuery);
          const ticketsData = ticketsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          // reservations
          const reservationsColRef = collection(firestore, 'reservations');
          const reservationsQuery = query(
            reservationsColRef,
            where('active', '==', true),
            where('studioId', '==', studioId),
            where('userId', '==', userId)
          );
          const reservationsSnapshot = await getDocs(reservationsQuery);
          const reservationsData = reservationsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          const now = new Date();
          const futureReservations = reservationsData.filter(reservation => {
            const endDate = new Date(reservation.endDate);
            return endDate > now && reservation.type !== 'block';
          });
          const sortedReservations = futureReservations.sort(
            (a, b) => new Date(a.startDate) - new Date(b.startDate)
          );

          // ユーザー情報をセット
          setUser({
            ...userData,
            tickets: ticketsData,
            studio: studioData,
            reservations: sortedReservations,
            subscriptionId: '', // 初期状態
            nextSubscriptionDate: '', // 初期状態
          });
        } else {
          console.log('ユーザーが見つかりません。');
        }
      } catch (error) {
        console.error('データの取得に失敗しました:', error);
        toast({
          title: 'エラー',
          description: 'データの取得に失敗しました。',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    };

    fetchUser();
  }, [studioId, userId, toast]);

  // ─────────────────────────────────────────────────────────
  // 2. サブスクリプション情報の取得（次回決済日時の取得 & subscriptionId 反映）
  // ─────────────────────────────────────────────────────────
  useEffect(() => {
    if (!user) return;

    const fetchSubscription = async () => {
      try {
        // customers/{uid}/subscriptions
        const subsColRef = collection(
          firestore,
          `customers/${user.id}/subscriptions`
        );
        const subsQuery = query(subsColRef, where('status', '==', 'active'));
        const subsSnap = await getDocs(subsQuery);

        if (subsSnap.empty) {
          // アクティブサブスクなし
          setUser(prev => {
            // subscriptionId や nextSubscriptionDate がすでに空なら変更しない
            if (
              prev.subscriptionId === '' &&
              prev.nextSubscriptionDate === ''
            ) {
              return prev;
            }
            return { ...prev, subscriptionId: '', nextSubscriptionDate: '' };
          });
          return;
        }

        // アクティブなサブスクリプションのうち、ここでは複数あっても先頭を使う
        const activeSubs = subsSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        const mainSub = activeSubs[0];

        // 次の課金日時を文字列として整形
        let nextDateString = '';
        if (mainSub.current_period_end?.toDate) {
          const dateObj = mainSub.current_period_end.toDate();
          nextDateString = dateObj.toISOString();
        } else if (mainSub.current_period_end) {
          const dateObj = new Date(mainSub.current_period_end * 1000);
          nextDateString = dateObj.toISOString();
        }

        // user.subscriptionId や user.nextSubscriptionDate を上書き
        setUser(prev => {
          // 値が変わらなければ更新しない
          if (
            prev.subscriptionId === mainSub.id &&
            prev.nextSubscriptionDate === nextDateString
          ) {
            return prev;
          }
          return {
            ...prev,
            subscriptionId: mainSub.id,
            nextSubscriptionDate: nextDateString,
          };
        });
      } catch (error) {
        console.error('サブスクリプション取得に失敗:', error);
      }
    };

    fetchSubscription();
  }, [user]);

  if (!user) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner mr={3} />
        <Text fontSize="xl">データ読み込み中...</Text>
      </Flex>
    );
  }

  // user.nextSubscriptionDate を読み取り → JS Date で表示
  const getNextSubscriptionDateText = () => {
    if (!user.nextSubscriptionDate) return 'なし';
    try {
      const dateObj = new Date(user.nextSubscriptionDate);
      if (isNaN(dateObj.getTime())) {
        return 'なし';
      }
      return dateObj.toLocaleDateString('ja-JP', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'short',
      });
    } catch (e) {
      return 'なし';
    }
  };

  // メニューリンク配列
  const links = [
    {
      text: `チケット管理（有効期限内：${user?.tickets?.length}枚）`,
      path: `/studios/${studioId}/users/${userId}/tickets`,
    },
    {
      text: `ご予約の管理（来店ご予定：${user?.reservations?.length}件）`,
      path: `/studios/${studioId}/users/${userId}/reservations`,
    },
    {
      text: `専用枠の設定（今の専用枠：${user?.fixies?.length || 0}枠）`,
      path: `/studios/${studioId}/users/${userId}/set-fixies`,
    },
    {
      text: `プランの変更（今のプラン：月${user?.plan}）`,
      path: `/studios/${studioId}/users/${userId}/allow-change-plan`,
    },
    {
      text: `退会の手続き（継続：${calculateContinuationPeriod(
        user?.createdAt
      )})`,
      path: `/studios/${studioId}/users/${userId}/allow-terminate`,
    },
  ];

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Heading as="h1" size="sm" textAlign="center" mt="24px" mb="20px">
        {user.fullName}さんの管理ページ
      </Heading>

      <Text fontSize="sm" textAlign="center" mb="20px">
        次回決済：{getNextSubscriptionDateText()}
      </Text>

      {links.map((link, index) => (
        <FormLink
          key={index}
          onClick={() => navigate(link.path, { state: { user: user } })}
        >
          {link.text}
        </FormLink>
      ))}

      <BackButton />
    </Container>
  );
};

export default User;
