import React, { useState, useEffect } from 'react';
import {
  Container,
  VStack,
  Input,
  Button,
  FormLabel,
  Flex,
  Select,
  Textarea,
  useToast,
  HStack,
  Circle,
  Box,
  Checkbox,
  Link,
} from '@chakra-ui/react';
import { useAuth } from '../AuthProvider';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import Logo from './Logo';
import { sendDiscordNotification } from '../utils/discord';

const Hearing = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  // Hooksは条件分岐の外で宣言
  const [step, setStep] = useState(1);

  // currentUserが存在しない場合の処理
  useEffect(() => {
    if (!currentUser) {
      navigate('/trial');
    }
  }, [currentUser, navigate]);

  // 過去のヒアリングデータを取得
  const previousHearing = currentUser?.hearing || {};

  const [formData, setFormData] = useState({
    fullName: previousHearing.fullName || currentUser?.fullName || '',
    furigana: previousHearing.furigana || '',
    dateOfBirth: previousHearing.dateOfBirth || '1995-06-15',
    gender: previousHearing.gender || '女性',
    postalcode: previousHearing.postalcode || '',
    address: previousHearing.address || '',
    occupation: previousHearing.occupation || '',
    purpose: previousHearing.purpose || '',
    exerciseHabits: previousHearing.exerciseHabits || '',
    medicalHistory: previousHearing.medicalHistory || '',
    painAreas: previousHearing.painAreas || '',
    pregnancyMonths: previousHearing.pregnancyMonths || '',
    lessonIntensity: previousHearing.lessonIntensity || '',
    reasonForTrial: previousHearing.reasonForTrial || '',
    message: previousHearing.message || '',
    isInstructor: previousHearing.isInstructor || false,
    agreedToTerms: previousHearing.agreedToTerms || false,
  });

  const handleInputChange = e => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validateStep = currentStep => {
    switch (currentStep) {
      case 1:
        return (
          formData.fullName &&
          formData.furigana &&
          formData.dateOfBirth &&
          formData.gender
        );
      case 2:
        return formData.postalcode && formData.address && formData.occupation;
      case 3:
        return formData.purpose && formData.exerciseHabits;
      case 4:
        return formData.lessonIntensity;
      case 5:
        return (
          formData.reasonForTrial &&
          formData.agreedToTerms &&
          !formData.isInstructor
        );
      default:
        return false;
    }
  };

  const handleSubmit = async () => {
    if (!validateStep(5)) {
      toast({
        title: 'エラー',
        description:
          '全ての必須項目を入力し、チェックボックスを選択してください',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const userRef = doc(firestore, 'users', currentUser.uid);
      await updateDoc(userRef, {
        hearing: formData,
        fullKana: formData.furigana,
        dateOfBirth: formData.dateOfBirth,
        sex: formData.gender,
        postalcode: formData.postalcode,
        address: formData.address,
        occupation: formData.occupation,
        purpose: formData.purpose,
        exerciseHabits: formData.exerciseHabits,
        medicalHistory: formData.medicalHistory,
        painAreas: formData.painAreas,
        pregnancyMonths: formData.pregnancyMonths,
        lessonIntensity: formData.lessonIntensity,
        reason: formData.reasonForTrial,
        message: formData.message,
        isInstructor: formData.isInstructor,
        agreedToTerms: formData.agreedToTerms,
      });

      // Discord通知を送信
      const embeds = [
        {
          title: 'ヒアリングフォームの送信',
          description: `${formData.fullName} 様がヒアリングフォームを送信しました。`,
          color: 65280, // 緑色
          fields: [
            { name: 'お名前', value: formData.fullName, inline: true },
            { name: '性別', value: formData.gender, inline: true },
            { name: '生年月日', value: formData.dateOfBirth, inline: true },
            { name: 'メールアドレス', value: currentUser.email, inline: true },
            {
              name: '体験を申し込んだきっかけ',
              value: formData.reasonForTrial,
            },
            { name: '郵便番号', value: formData.postalcode },
            { name: '住所', value: formData.address },
            { name: '職業', value: formData.occupation },
            { name: '運動習慣', value: formData.exerciseHabits },
            { name: '既往歴', value: formData.medicalHistory },
            { name: '痛みのある部位', value: formData.painAreas },
            { name: '妊娠月数', value: formData.pregnancyMonths },
            { name: '目的', value: formData.purpose },
            { name: '事前に伝えておきたいこと', value: formData.message },
            { name: 'レッスンの強度', value: formData.lessonIntensity },
            {
              name: 'インストラクターではない',
              value: formData.isInstructor ? 'いいえ' : 'はい',
            },
            {
              name: '利用規約に同意する',
              value: formData.agreedToTerms ? 'はい' : 'いいえ',
            },
          ],
          timestamp: new Date().toISOString(),
        },
      ];

      await sendDiscordNotification(currentUser.studio.slug, embeds);

      toast({
        title: '送信完了',
        description: 'ご回答ありがとうございます。',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/waiting');
    } catch (error) {
      console.error('Error saving hearing data: ', error);
      toast({
        title: 'エラー',
        description: '送信中にエラーが発生しました。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const stepCircles = () => {
    const steps = [1, 2, 3, 4, 5];
    return (
      <HStack spacing={4} justify="center" mb={4}>
        {steps.map(num => (
          <Circle
            key={num}
            size="40px"
            bg={
              num === step ? 'blue.500' : num < step ? 'green.500' : 'gray.200'
            }
            color="white"
            cursor="pointer"
            onClick={() => setStep(num)}
          >
            {num < step ? '✓' : num}
          </Circle>
        ))}
      </HStack>
    );
  };

  const renderStepContent = currentStep => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>お名前（漢字）</FormLabel>
              <Input
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                placeholder="お名前（漢字）"
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>お名前のふりがな</FormLabel>
              <Input
                name="furigana"
                value={formData.furigana}
                onChange={handleInputChange}
                placeholder="お名前のふりがな"
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>生年月日</FormLabel>
              <Input
                name="dateOfBirth"
                type="date"
                value={formData.dateOfBirth}
                onChange={handleInputChange}
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>性別</FormLabel>
              <Select
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
              >
                <option value="">選択してください</option>
                <option value="female">女性</option>
                <option value="male">男性</option>
              </Select>
            </Flex>
          </>
        );
      case 2:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>郵便番号</FormLabel>
              <Input
                name="postalcode"
                value={formData.postalcode}
                onChange={handleInputChange}
                placeholder="郵便番号"
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>住所</FormLabel>
              <Input
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="住所"
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>職業</FormLabel>
              <Select
                name="occupation"
                value={formData.occupation}
                onChange={handleInputChange}
              >
                <option value="">選択してください</option>
                <option value="会社員">会社員</option>
                <option value="公務員">公務員</option>
                <option value="士業">士業</option>
                <option value="医師">医師</option>
                <option value="看護師">看護師</option>
                <option value="IT関係">IT関係</option>
                <option value="経営者">経営者</option>
                <option value="フリーランス">フリーランス</option>
                <option value="パート・アルバイト">パート・アルバイト</option>
                <option value="主婦">主婦</option>
                <option value="学生">学生</option>
                <option value="その他">その他</option>
              </Select>
            </Flex>
          </>
        );
      case 3:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>目的</FormLabel>
              <Input
                name="purpose"
                value={formData.purpose}
                onChange={handleInputChange}
                placeholder="例: 姿勢改善、痩せる、健康など"
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>運動習慣</FormLabel>
              <Textarea
                name="exerciseHabits"
                value={formData.exerciseHabits}
                onChange={handleInputChange}
                placeholder="現在の運動習慣について"
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>既往歴</FormLabel>
              <Textarea
                name="medicalHistory"
                value={formData.medicalHistory}
                onChange={handleInputChange}
                placeholder="過去の病歴や怪我など"
              />
            </Flex>
          </>
        );
      case 4:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>痛みのある部位</FormLabel>
              <Input
                name="painAreas"
                value={formData.painAreas}
                onChange={handleInputChange}
                placeholder="例: 腰、肩など"
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>妊娠中の場合、何ヶ月か</FormLabel>
              <Input
                name="pregnancyMonths"
                type="number"
                value={formData.pregnancyMonths}
                onChange={handleInputChange}
                placeholder="妊娠月数"
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>レッスンの強度希望</FormLabel>
              <Select
                name="lessonIntensity"
                value={formData.lessonIntensity}
                onChange={handleInputChange}
              >
                <option value="">選択してください</option>
                <option value="弱め">弱め</option>
                <option value="普通">普通</option>
                <option value="強め">強め</option>
              </Select>
            </Flex>
          </>
        );
      case 5:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>体験を申し込んだきっかけ</FormLabel>
              <Select
                name="reasonForTrial"
                value={formData.reasonForTrial}
                onChange={handleInputChange}
              >
                <option value="">選択してください</option>
                <option value="すぐ通い始めたい">すぐ通い始めたい</option>
                <option value="他と比較したい">他と比較したい</option>
                <option value="初めてで気になって">初めてで気になって</option>
                <option value="その他">その他</option>
              </Select>
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel>事前に伝えておきたいこと</FormLabel>
              <Textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="インストラクターに事前に伝えておきたいことがあればご記入ください"
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full" mt={4}>
              <Checkbox
                isChecked={!formData.isInstructor}
                onChange={e =>
                  setFormData(prev => ({
                    ...prev,
                    isInstructor: !e.target.checked,
                  }))
                }
              >
                私はインストラクターではありません
              </Checkbox>
              <Checkbox
                isChecked={formData.agreedToTerms}
                onChange={handleInputChange}
                name="agreedToTerms"
                mt={2}
              >
                <Link href="/terms-of-service" color="blue.500">
                  利用規約
                </Link>
                に同意します
              </Checkbox>
            </Flex>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'10'}
      mt={4}
      pt={8}
      pb="4"
      maxW="container.md"
      centerContent
    >
      <Logo text="ヒアリングフォーム" />
      {stepCircles()}
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
        width="100%"
      >
        <VStack spacing="12px" align="stretch">
          {renderStepContent(step)}
          <Flex
            justifyContent={step === 1 ? 'center' : 'space-between'}
            width="full"
            mt={4}
          >
            {step > 1 && (
              <Button onClick={() => setStep(step - 1)}>戻る</Button>
            )}
            {step < 5 ? (
              <Button
                colorScheme="blue"
                onClick={() => {
                  if (validateStep(step)) {
                    setStep(step + 1);
                  } else {
                    toast({
                      title: 'エラー',
                      description: '全ての必須項目を入力してください',
                      status: 'error',
                      duration: 3000,
                      isClosable: true,
                    });
                  }
                }}
              >
                次へ
              </Button>
            ) : (
              <Button
                colorScheme="green"
                onClick={handleSubmit}
                isDisabled={!validateStep(5)}
              >
                送信
              </Button>
            )}
          </Flex>
        </VStack>
      </Box>
    </Container>
  );
};

export default Hearing;
