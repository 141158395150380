import React, { useState, useEffect, useCallback } from 'react';
import {
  Text,
  Container,
  Button,
  Box,
  Input,
  VStack,
  Select,
  FormLabel,
  Flex,
  useToast,
  HStack,
  Circle,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import {
  auth,
  firestore,
  collection,
  doc,
  setDoc,
  onSnapshot,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
  getDoc,
  arrayUnion,
} from '../firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useAuth } from '../AuthProvider';
import LoadingModal from './LoadingModal';
import Logo from './Logo';
import { sendDiscordNotification } from '../utils/discord';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { addEventToGoogleCalendar } from '../utils/googleCalendar';
import { v4 as uuid } from 'uuid';

const Trial = () => {
  const { currentUser, refreshUser } = useAuth();
  const [step, setStep] = useState(
    Number(localStorage.getItem('trialStep')) || (currentUser ? 2 : 1)
  );
  const [completedSteps, setCompletedSteps] = useState(() => {
    const storedSteps = JSON.parse(localStorage.getItem('trialCompletedSteps'));
    if (storedSteps) {
      return storedSteps;
    } else if (currentUser) {
      return [true, false, false];
    } else {
      return [false, false, false];
    }
  });
  const [registering, setRegistering] = useState(false);
  const [processingReservation, setProcessingReservation] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [path, setPath] = useState('');

  const [studioId, setStudioId] = useState('');
  const [studioName, setStudioName] = useState('');
  const [studios, setStudios] = useState([]);
  const [studioData, setStudioData] = useState(null);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [selectedDateTime, setSelectedDateTime] = useState(() => {
    const stored = localStorage.getItem('selectedDateTime');
    if (stored) {
      const parsed = JSON.parse(stored);
      parsed.date = new Date(parsed.date); // Dateオブジェクトに変換
      return parsed;
    }
    return null;
  });
  const [dateOffset, setDateOffset] = useState(0);
  const [initialPrice, setInitialPrice] = useState({});
  const [preparing, setPreparing] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextDays(),
    onSwipedRight: () => handlePrevDays(),
  });

  const timeSlots = [
    { hour: 8, minute: 0 },
    { hour: 9, minute: 0 },
    { hour: 10, minute: 0 },
    { hour: 11, minute: 0 },
    { hour: 12, minute: 30 },
    { hour: 13, minute: 30 },
    { hour: 14, minute: 30 },
    { hour: 15, minute: 30 },
    { hour: 16, minute: 30 },
    { hour: 17, minute: 30 },
    { hour: 19, minute: 0 },
    { hour: 20, minute: 0 },
    { hour: 21, minute: 0 },
    { hour: 22, minute: 0 },
  ];

  const isStepCompleted = step => {
    switch (step) {
      case 1:
        return email && password && fullName;
      case 2:
        return paymentCompleted || currentUser?.trialPaid;
      case 3:
        return selectedDateTime && studioId;
      default:
        return false;
    }
  };

  useEffect(() => {
    // Fetch available studios
    const studiosCollection = collection(firestore, 'studios');
    const unsubscribe = onSnapshot(
      studiosCollection,
      snapshot => {
        const studiosArray = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        const openStudiosArray = studiosArray.filter(
          studio => studio.open === true
        );
        setStudios(openStudiosArray);
        if (openStudiosArray?.length > 0 && !studioId) {
          setStudioId(openStudiosArray[0]?.id);
          setStudioName(openStudiosArray[0]?.name);
        }
      },
      error => console.error('Error fetching studios:', error)
    );

    return () => unsubscribe();
  }, [studioId]);

  useEffect(() => {
    if (studioId) {
      const fetchStudioData = async () => {
        const studioRef = doc(firestore, 'studios', studioId);
        const studioSnap = await getDoc(studioRef);
        if (studioSnap.exists()) {
          setStudioData(studioSnap.data());
        } else {
          console.log('No such studio!');
        }
      };
      fetchStudioData();
    }
  }, [studioId]);

  useEffect(() => {
    if (currentUser) {
      setLoadingUserData(false);
      if (!completedSteps[0]) {
        setCompletedSteps(prev => {
          const newCompletedSteps = [...prev];
          newCompletedSteps[0] = true;
          localStorage.setItem(
            'trialCompletedSteps',
            JSON.stringify(newCompletedSteps)
          );
          return newCompletedSteps;
        });
        setStep(2);
        localStorage.setItem('trialStep', '2');
      }
    }
  }, [completedSteps, currentUser]);

  useEffect(() => {
    if (currentUser?.trialPaid) {
      setCompletedSteps(prev => {
        const newCompletedSteps = [...prev];
        newCompletedSteps[1] = true;
        localStorage.setItem(
          'trialCompletedSteps',
          JSON.stringify(newCompletedSteps)
        );
        return newCompletedSteps;
      });
      setStep(3);
      localStorage.setItem('trialStep', '3');
    }
    if (currentUser?.trialId?.length > 0) {
      setCompletedSteps(prev => {
        const newCompletedSteps = [...prev];
        newCompletedSteps[2] = true;
        localStorage.setItem(
          'trialCompletedSteps',
          JSON.stringify(newCompletedSteps)
        );
        return newCompletedSteps;
      });
      navigate('/waiting');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (!currentUser || !studioData) return;

    const fetchInitialProducts = async () => {
      const productsCollectionRef = collection(firestore, 'products');
      const q = query(
        productsCollectionRef,
        where('active', '==', true),
        where('stripe_metadata_type', '==', 'live'),
        where('stripe_metadata_category', '==', 'trial')
      );
      const querySnapshot = await getDocs(q);
      const productsData = await Promise.all(
        querySnapshot.docs.map(async doc => {
          const productData = { id: doc.id, ...doc.data() };
          const pricesCollectionRef = collection(
            firestore,
            `products/${doc.id}/prices`
          );
          const priceQuerySnapshot = await getDocs(pricesCollectionRef);
          productData.prices = priceQuerySnapshot.docs.map(priceDoc => ({
            id: priceDoc.id,
            ...priceDoc.data(),
          }));
          return productData;
        })
      );
      const matchingPrices = productsData[0]?.prices || [];
      let matchingPrice;
      if (matchingPrices.length === 1) {
        matchingPrice = matchingPrices[0];
      } else if (matchingPrices.length > 1) {
        const sexMatchingPrice = matchingPrices.find(
          price => price.metadata.sex === currentUser.sex
        );
        if (sexMatchingPrice) {
          matchingPrice = sexMatchingPrice;
        }
      }
      setInitialPrice(matchingPrice);
    };
    fetchInitialProducts();
  }, [currentUser, studioData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('success')) {
      setPaymentCompleted(true);
      setCompletedSteps(prev => {
        const newCompletedSteps = [...prev];
        newCompletedSteps[1] = true; // Mark step 2 as completed
        localStorage.setItem(
          'trialCompletedSteps',
          JSON.stringify(newCompletedSteps)
        );
        return newCompletedSteps;
      });
      setStep(3);
      localStorage.setItem('trialStep', '3');
      toast({
        title: 'お支払い完了',
        description: 'お支払いが完了しました。次のステップに進みます。',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      localStorage.removeItem('checkoutSessionUrl');
    } else if (queryParams.get('canceled')) {
      localStorage.removeItem('checkoutSessionUrl');
      toast({
        title: 'お支払いキャンセル',
        description: 'お支払いがキャンセルされました。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  const getCheckoutSessionUrl = () =>
    localStorage.getItem('checkoutSessionUrl');

  const createCheckoutSession = async () => {
    if (!currentUser || !initialPrice?.id || getCheckoutSessionUrl()) return;
    const sessionId = uuid();
    const checkoutSessionRef = doc(
      firestore,
      `customers/${currentUser.uid}/checkout_sessions/${sessionId}`
    );

    try {
      await setDoc(checkoutSessionRef, {
        mode: 'payment',
        line_items: [{ price: initialPrice?.id, quantity: 1 }],
        success_url: window.location.origin + '/trial?success=true',
        cancel_url: window.location.origin + '/trial?canceled=true',
      });

      onSnapshot(checkoutSessionRef, docSnap => {
        const data = docSnap.data();
        if (data?.url) {
          localStorage.setItem('checkoutSessionUrl', data.url);
          window.location.assign(data.url);
        }
      });
    } catch (error) {
      console.error('Checkout session creation failed:', error);
      setPreparing(false);
      toast({
        title: 'エラー',
        description: 'お支払いに失敗しました。もう一度お試しください。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleStripePayment = async () => {
    if (preparing) return;
    try {
      if (getCheckoutSessionUrl()) {
        // window.location.assign(getCheckoutSessionUrl());
        setPreparing(true);
        await createCheckoutSession();
      } else {
        setPreparing(true);
        await createCheckoutSession();
        // ローディング画面はcreateCheckoutSession内で処理
      }
    } catch (error) {
      console.error('Error in payment process:', error);
      setPreparing(false);
      toast({
        title: 'エラー',
        description: 'お支払いに失敗しました。もう一度お試しください。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSignUp = async () => {
    if (!isStepCompleted(1)) {
      toast({
        title: 'エラー',
        description: 'メールアドレス、パスワード、氏名を入力してください。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setRegistering(true);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const userRef = doc(firestore, 'users', user.uid);
      await setDoc(
        userRef,
        {
          email: email,
          fullName: fullName,
          displayName: displayName,
          createdAt: new Date(),
          updatedAt: new Date(),
          path: path,
          // studioIdは後で設定する
          belongStudioIds: {},
          manageStudioIds: [],
          workingStudioIds: [],
          sex: 'female',
          status: false,
          trial_lesson: false,
          trial_status: 0,
          member: false,
          instructor: false,
          initial: false,
          initial_discount: 100,
          trial_discount: 50,
          plan: 0,
          fixies: [],
          allowEmail: true,
          login: false,
          active: true,
        },
        { merge: true }
      );

      const studioRef = doc(firestore, 'studios', studioId);
      await setDoc(studioRef, { users: arrayUnion(userRef) }, { merge: true });
      // ユーザー情報を更新
      await refreshUser();

      setRegistering(false);
      setCompletedSteps(prev => {
        const newCompletedSteps = [...prev];
        newCompletedSteps[0] = true;
        localStorage.setItem(
          'trialCompletedSteps',
          JSON.stringify(newCompletedSteps)
        );
        return newCompletedSteps;
      });
      setStep(2);
      localStorage.setItem('trialStep', '2');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        toast({
          title: 'エラー',
          description:
            '既に登録済みのメールアドレスです。パスワードを確認してください。',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setRegistering(false);
      } else {
        console.error('Error during sign-up', error);
        // toast({
        //   title: 'エラー',
        //   description: '登録に失敗しました。',
        //   status: 'error',
        //   duration: 3000,
        //   isClosable: true,
        // });
        setRegistering(false);
      }
    }
  };

  const getNextDays = offset => {
    const days = [];
    const today = new Date();
    today.setDate(today.getDate() + offset);
    let currentMonth = today.getMonth();
    for (let i = 1; i < 8; i++) {
      const day = new Date(today);
      day.setDate(today.getDate() + i);
      if (day.getMonth() !== currentMonth) {
        currentMonth = day.getMonth();
        days.push({ date: day, newMonth: true });
      } else {
        days.push({ date: day, newMonth: false });
      }
    }
    return days;
  };
  const days = getNextDays(dateOffset) || [];

  const fetchReservations = useCallback(async () => {
    if (!studioId) return;

    setFetching(true);
    const reservationsRef = collection(firestore, 'reservations');
    const q = query(
      reservationsRef,
      where('studioId', '==', studioId),
      where('active', '==', true),
      where('startDate', '>=', new Date().toISOString())
    );
    const querySnapshot = await getDocs(q);
    const reservations = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    setReservations(reservations);
    setFetching(false);
  }, [studioId]);

  useEffect(() => {
    if (step === 3) {
      fetchReservations();
    }
  }, [fetchReservations, step]);

  const combineDateTime = (date, hour, minute) => {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    if (!(date instanceof Date) || isNaN(date)) {
      console.error('Invalid date object:', date);
      return null;
    }

    const dateStr = date
      .toLocaleDateString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\//g, '-');

    const dateTimeStr = `${dateStr}T${String(hour).padStart(2, '0')}:${String(
      minute
    ).padStart(2, '0')}`;

    return dateTimeStr;
  };

  const isTimeSlotReserved = (date, hour, minute) => {
    const slotStart = new Date(date);
    slotStart.setHours(hour, minute, 0, 0);
    const slotEnd = new Date(slotStart.getTime() + 60 * 60 * 1000);

    return reservations.some(reservation => {
      const reservationStart = new Date(reservation.startDate);
      const reservationEnd = new Date(reservation.endDate);

      return slotStart < reservationEnd && slotEnd > reservationStart;
    });
  };

  const getMonthRange = days => {
    if (!days || days.length === 0) return '';
    const months = [...new Set(days.map(day => day.date.getMonth() + 1))];
    return months.length > 1
      ? `${months[0]}月・${months[1]}月`
      : `${months[0]}月`;
  };

  const isShiftAvailable = useCallback(
    (date, hour, minute) => {
      if (!studioData || !studioData.shifts) {
        return false;
      }
      const weekday = ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];
      const timeStr = `${String(hour).padStart(2, '0')}${String(
        minute
      ).padStart(2, '0')}`;
      const shiftKey = `${weekday}${timeStr}`;
      return (
        studioData.shifts[shiftKey] && studioData.shifts[shiftKey].length > 0
      );
    },
    [studioData]
  );

  const isFixedSlot = useCallback(
    (date, hour, minute) => {
      if (!studioData || !studioData.fixies) {
        return false;
      }
      const weekday = ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];
      const timeStr = `${String(hour).padStart(2, '0')}${String(
        minute
      ).padStart(2, '0')}`;
      return studioData.fixies.some(
        fixie => fixie.weekday === weekday && fixie.start === timeStr
      );
    },
    [studioData]
  );

  const formatDateTime = (date, hour, minute) => {
    const day = new Date(date);
    const formattedDate = `${day.getFullYear()}年${
      day.getMonth() + 1
    }月${day.getDate()}日`;
    return `${formattedDate} ${String(hour).padStart(2, '0')}:${String(
      minute
    ).padStart(2, '0')}`;
  };

  const handleReserveClick = (date, hour, minute) => {
    const selected =
      selectedDateTime &&
      selectedDateTime.date.toDateString() === date.toDateString() &&
      selectedDateTime.hour === hour &&
      selectedDateTime.minute === minute;

    if (selected) {
      setSelectedDateTime(null);
      localStorage.removeItem('selectedDateTime');
    } else {
      if (isTimeSlotReserved(date, hour, minute)) {
        alert('この時間帯は他の人の予約枠です。');
      } else {
        const newSelectedDateTime = {
          date: date, // Dateオブジェクトをそのまま使用
          hour,
          minute,
        };
        setSelectedDateTime(newSelectedDateTime);
        localStorage.setItem(
          'selectedDateTime',
          JSON.stringify(newSelectedDateTime)
        );
      }
    }
  };

  const confirmReservation = async () => {
    if (!currentUser) {
      toast({
        title: 'エラー',
        description: 'ユーザー情報を取得できません。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setProcessingReservation(true);

    // スタジオIDをユーザーデータに保存
    const userRef = doc(firestore, 'users', currentUser.uid);
    await updateDoc(userRef, {
      studioId: studioId,
      ['belongStudioIds.' + studioId]: studioName.slice(5),
    });

    const startDateTime = combineDateTime(
      selectedDateTime.date,
      selectedDateTime.hour,
      selectedDateTime.minute
    );
    const endDateTime = combineDateTime(
      selectedDateTime.date,
      selectedDateTime.hour + 1,
      selectedDateTime.minute
    );

    try {
      console.log('start');
      const eventData = await addEventToGoogleCalendar(
        currentUser,
        '体験',
        '体験予約画面',
        startDateTime,
        endDateTime,
        studioData
      );

      console.log('Google Calendar event added:', eventData);

      const weekday = ['日', '月', '火', '水', '木', '金', '土'][
        selectedDateTime.date.getDay()
      ];
      const timeStr = `${String(selectedDateTime.hour).padStart(
        2,
        '0'
      )}${String(selectedDateTime.minute).padStart(2, '0')}`;
      const shiftKey = `${weekday}${timeStr}`;
      const instructorId = studioData.shifts[shiftKey]?.[0];

      console.log(instructorId);

      const reservation = await addDoc(collection(firestore, 'reservations'), {
        startDate: startDateTime,
        endDate: endDateTime,
        userId: currentUser.uid,
        description: currentUser.fullName || '',
        studioId: studioId,
        ticketId: null,
        eventId: eventData.id,
        visited: false,
        active: true,
        changed: false,
        type: 'trial',
        instructorId: instructorId,
      });

      await updateDoc(userRef, {
        trialId: reservation.id,
        trialPaid: true,
      });

      const embeds = [
        {
          title: '【' + studioName + '】新規体験のご予約が入りました✨',
          description: '体験レッスン予約ページからお客さま自身が入力しました。',
          color: 15158332,
          fields: [
            {
              name: '氏名',
              value: currentUser.fullName,
              inline: true,
            },
            {
              name: 'LINE表示名',
              value: currentUser.displayName,
              inline: true,
            },
            {
              name: 'メールアドレス',
              value: currentUser.email,
              inline: true,
            },
            {
              name: '認知経路',
              value: path,
              inline: true,
            },
            {
              name: '開始時刻',
              value: `${startDateTime.replace('T', ' ')}`,
              inline: true,
            },
            {
              name: '終了時刻',
              value: `${endDateTime.replace('T', ' ')}`,
              inline: true,
            },
            {
              name: '担当者',
              value: currentUser?.studio?.instructors[instructorId] || '未定',
              inline: true,
            },
          ],
          footer: { text: 'UID: ' + currentUser.uid },
          timestamp: new Date().toISOString(),
        },
      ];

      await sendDiscordNotification(studioData.slug, embeds);
      await sendDiscordNotification('trial', embeds);
      await sendDiscordNotification('audit', embeds);

      // 不要なローカルストレージ項目をクリア
      localStorage.removeItem('selectedDateTime');
      localStorage.removeItem('checkoutSessionUrl');
      // 必要に応じて他の項目もクリア
      localStorage.removeItem('trialStep');
      localStorage.removeItem('trialCompletedSteps');

      setProcessingReservation(false);
      navigate('/waiting');
    } catch (error) {
      window.alert(
        'この枠は何らかの理由で予約できないため、別の日時でご予約ください。'
      );
      console.error('予約の追加に失敗しました', error);

      const errorEmbeds = [
        {
          title: '体験費用の支払い後の予約確定時にエラー発生',
          description:
            '体験費用はお支払いされましたが、予約の追加に失敗しました。',
          color: 16711680,
          fields: [
            {
              name: '対象者',
              value: currentUser?.email || '',
              inline: true,
            },
            {
              name: 'エラー内容',
              value: error.message,
              inline: false,
            },
          ],
          footer: {
            text: 'USER ID:' + currentUser?.uid,
          },
          timestamp: new Date().toISOString(),
        },
      ];
      await sendDiscordNotification('trial', errorEmbeds);
      await sendDiscordNotification('audit', errorEmbeds);

      setProcessingReservation(false);
      navigate('/trial');
    }
  };

  const handlePrevDays = () => {
    setDateOffset(prev => Math.max(prev - 7, 0));
  };

  const handleNextDays = () => {
    setDateOffset(prev => Math.min(prev + 7, 28));
  };

  const setSelectedStudio = id => {
    setStudioId(id);
    const selectedStudio = studios.find(s => s.id === id);
    setStudioName(selectedStudio?.name);
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const stepContent = () => {
    if ((loadingUserData && step !== 1) || registering) {
      // currentUserが完全にロードされるまでローディングを表示
      return <LoadingModal text="ユーザーデータを読み込み中..." />;
    }

    switch (step) {
      case 1:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                お名前 <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Input
                name="name"
                placeholder="漢字フルネーム"
                value={fullName}
                onChange={e => setFullName(e.target.value)}
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                LINEの表示名 <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Input
                name="displayName"
                placeholder="LINEの表示名"
                value={displayName}
                onChange={e => setDisplayName(e.target.value)}
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                メールアドレス <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Input
                name="email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                パスワード <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="パスワード"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? '隠す' : '見る'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Flex>

            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                medyを知ったきっかけ <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Select
                value={path}
                onChange={e => setPath(e.target.value)}
                placeholder="選択してください"
              >
                <option key="search" value="ウェブ検索">
                  ウェブ検索
                </option>
                <option key="instagram" value="Instagram">
                  Instagram
                </option>
                <option key="signborad" value="看板">
                  看板
                </option>
                <option key="leaflet" value="チラシ">
                  チラシ
                </option>
                <option key="map" value="Googleマップ">
                  Googleマップ
                </option>
                <option key="friend" value="友人の紹介">
                  友人の紹介
                </option>
              </Select>
            </Flex>

            <Button
              onClick={handleSignUp}
              colorScheme="blue"
              width="full"
              isLoading={registering}
            >
              登録して次へ
            </Button>
          </>
        );
      case 2:
        if (!currentUser || !initialPrice?.id || preparing) {
          return <LoadingModal text="処理中..." />;
        }
        return (
          <>
            <Text fontSize="14px" my="4" textAlign="center" fontWeight={'bold'}>
              体験レッスンご予約は先にお会計が必要です
            </Text>
            <Text fontSize="14px" mb="4" textAlign="center">
              ご希望の日時はお支払い後に確定します🙆‍♀️
              <br />
              <br />
              ▼
              <br />
              <br />
              通常8,000円のところ、半額の4,000円で
              <br />
              個人レッスンを受けられるキャンペーン中🎉
              <br />
              <br />
              さらに！体験後に感想をお書きいただくことで4,000円も全額キャッシュバック✨
              <br />
              <br />
              ▼
              <br />
              <br />
              実質
              <span style={{ color: 'red' }}>
                「無料」で体験できる お得な期間
              </span>
              です🙌
              <br />
              <br />
              この機会にぜひ
              <br />
              medyのマシンピラティスをお試し下さい🌟
              <br />
              <br />
              なお、medyは女性専門のプライベートスタジオです🌸
              <br />
            </Text>
            <Button
              onClick={handleStripePayment}
              colorScheme="blue"
              width="full"
              isLoading={preparing}
              disabled={!currentUser || !initialPrice?.id}
            >
              カードでお支払い
            </Button>
            {!currentUser && (
              <Button
                onClick={() => setStep(1)}
                colorScheme="gray"
                width="full"
                mt={4}
              >
                戻る
              </Button>
            )}
            <Text
              fontSize={'xs'}
              color={'gray'}
              py={2}
              ml={2}
              textAlign={'left'}
            >
              ※ medyでは、前払い制を導入しております🙇‍♀️
              <br />
              　所定の操作で変更や返金も可能となっております🙌
              <br />
              <br />※ インストラクターの方は公式LINEでお伝え下さい。
              <br />
              　通常の体験価格8,000円で受講が可能です🌟
            </Text>
          </>
        );
      case 3:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                スタジオを選択してください{' '}
                <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Select
                value={studioId}
                onChange={e => setSelectedStudio(e.target.value)}
              >
                {studios?.map(s => (
                  <option key={s?.id} value={s?.id}>
                    {s?.name}
                  </option>
                ))}
              </Select>
            </Flex>
            {fetching && (
              <LoadingModal text="予約が可能な時間を問い合わせ中..." />
            )}
            {processingReservation && <LoadingModal text="予約を処理中..." />}
            {!fetching && !processingReservation && (
              <Box
                borderWidth="0px"
                borderRadius="xl"
                overflow="hidden"
                p={4}
                mt={4}
                backgroundColor={'#fdfdfd'}
                boxShadow="sm"
                {...handlers}
              >
                <HStack justifyContent="space-between" mb={2}>
                  <Button
                    size="sm"
                    onClick={handlePrevDays}
                    disabled={dateOffset === 0}
                    colorScheme={dateOffset === 0 ? 'gray' : 'teal'}
                  >
                    前へ
                  </Button>
                  <Text fontWeight="bold">{getMonthRange(days)}</Text>
                  <Button
                    size="sm"
                    onClick={handleNextDays}
                    disabled={dateOffset >= 21}
                    colorScheme={dateOffset >= 21 ? 'gray' : 'teal'}
                  >
                    次へ
                  </Button>
                </HStack>
                <Table variant="simple" size="xs">
                  <Thead>
                    <Tr>
                      <Th style={{ textAlign: 'center', fontSize: 12 }}>
                        時間
                      </Th>
                      {days.length > 0 &&
                        days.map((day, index) => (
                          <Th
                            style={{
                              textAlign: 'center',
                              marginBottom: '4px',
                              fontSize: 10,
                              color:
                                day.date.getDay() === 0
                                  ? 'red'
                                  : day.date.getDay() === 6
                                  ? 'blue'
                                  : 'black',
                            }}
                            key={day.date.toDateString()}
                          >
                            {`${day.date.getDate()}日 (${
                              ['日', '月', '火', '水', '木', '金', '土'][
                                day.date.getDay()
                              ]
                            })`}
                          </Th>
                        ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {timeSlots.length > 0 &&
                      timeSlots.map(slot => (
                        <Tr key={`${slot.hour}:${slot.minute}`}>
                          <Td
                            style={{
                              textAlign: 'center',
                              fontSize: 12,
                              paddingRight: 4,
                            }}
                          >{`${String(slot.hour).padStart(2, '0')}:${String(
                            slot.minute
                          ).padStart(2, '0')}`}</Td>
                          {days.map(day => (
                            <Td
                              key={`${day.date.toDateString()}-${slot.hour}:${
                                slot.minute
                              }`}
                              onClick={() =>
                                handleReserveClick(
                                  day.date,
                                  slot.hour,
                                  slot.minute
                                )
                              }
                              style={{
                                fontSize: 12,
                                cursor:
                                  !isFixedSlot(
                                    day.date,
                                    slot.hour,
                                    slot.minute
                                  ) &&
                                  !isTimeSlotReserved(
                                    day.date,
                                    slot.hour,
                                    slot.minute
                                  ) &&
                                  isShiftAvailable(
                                    day.date,
                                    slot.hour,
                                    slot.minute
                                  )
                                    ? 'pointer'
                                    : 'default',
                                textAlign: 'center',
                                backgroundColor:
                                  selectedDateTime &&
                                  selectedDateTime.date.toDateString() ===
                                    day.date.toDateString() &&
                                  selectedDateTime.hour === slot.hour &&
                                  selectedDateTime.minute === slot.minute
                                    ? '#afc7fb'
                                    : !isFixedSlot(
                                        day.date,
                                        slot.hour,
                                        slot.minute
                                      ) &&
                                      isShiftAvailable(
                                        day.date,
                                        slot.hour,
                                        slot.minute
                                      ) &&
                                      !isTimeSlotReserved(
                                        day.date,
                                        slot.hour,
                                        slot.minute
                                      )
                                    ? '#eafffa'
                                    : 'silver',
                              }}
                            >
                              {isFixedSlot(day.date, slot.hour, slot.minute) ||
                              isTimeSlotReserved(
                                day.date,
                                slot.hour,
                                slot.minute
                              ) ||
                              !isShiftAvailable(
                                day.date,
                                slot.hour,
                                slot.minute
                              )
                                ? '×'
                                : '◯'}
                            </Td>
                          ))}
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </Box>
            )}
            <VStack spacing={2} mt={4} align="center">
              {selectedDateTime && (
                <Text>
                  {formatDateTime(
                    selectedDateTime.date,
                    selectedDateTime.hour,
                    selectedDateTime.minute
                  )}
                </Text>
              )}
              {!selectedDateTime && (
                <>
                  <Text fontSize="12" color="red">
                    体験レッスンの希望時間を選択してください
                  </Text>
                </>
              )}
              <Button
                colorScheme="blue"
                my={2}
                onClick={confirmReservation}
                isDisabled={!selectedDateTime || !currentUser}
              >
                予約を確定する
              </Button>
            </VStack>
          </>
        );
      default:
        return null;
    }
  };

  const stepCircles = () => {
    const steps = [1, 2, 3];
    return (
      <HStack spacing={4} justify="center" mb={4}>
        {steps.map(num => {
          const isClickable =
            num === 1 || completedSteps.slice(0, num - 1).every(Boolean);
          return (
            <Circle
              key={num}
              size="40px"
              bg={
                completedSteps[num - 1]
                  ? 'green.500'
                  : num === step
                  ? 'blue.500'
                  : 'gray.200'
              }
              color="white"
              cursor={isClickable ? 'pointer' : 'not-allowed'}
              onClick={() => isClickable && setStep(num)}
            >
              {completedSteps[num - 1] ? '✓' : num}
            </Circle>
          );
        })}
      </HStack>
    );
  };

  if (registering || processingReservation || preparing) {
    return <LoadingModal text="処理中..." />;
  }

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'10'}
      mt={4}
      pt={8}
      pb="4"
    >
      <Logo text="体験レッスンご予約の受付" />
      {stepCircles()}
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <VStack spacing="12px">{stepContent()}</VStack>
      </Box>
    </Container>
  );
};

export default Trial;
